import React, { Component, createRef, RefObject } from "react";
import { Card, Classes, Drawer, Elevation, Icon, Overlay, Position, H2 } from "@blueprintjs/core";
import ReactToPrint from "react-to-print";

// FIXME: Once https://github.com/facebook/create-react-app/issues/11770
// is fixed, remove the !file-loader! syntax from the SVG imports (and
// consequently remove the eslint disables).

import {
    Entry, Section, EDUCATION, EXPERIENCE, EXPERIENCE_OFFLINE, PUBLICATIONS,
    PUBS_IN_PREP, AWARDS, SKILLS
  } from "./constants";
import "./App.scss";
// eslint-disable-next-line
import githubLogo from "!file-loader!./github.svg";
import orcidLogo from "./ORCIDiD_iconvector.svg";
import gscholarLogo from "./Google_Scholar_logo.svg";
import linkedinLogo from "./linkedin.svg";
// eslint-disable-next-line
import qrCode from "!file-loader!./qr-ningyuan-io.svg";

interface SubsectionState {
  isOpen: boolean;
}

interface OverlayState {
  isOpen: boolean;
}

function App() {
  return (
    <div className="App">
      <OverlayTip />
      <header className="header">
        <h1 className="Name"><span className="print-only-inline">Lee </span>Ning Yuan</h1>
        <p className="Contact online-only">
          <code>ning&lt;at&gt;u.duke.nus.edu</code>;&nbsp;
          <a href="https://orcid.org/0000-0002-2258-9851">
            <img src={orcidLogo} alt="ORCID logo" height="16em" /></a>,&nbsp;
          <a href="https://scholar.google.com/citations?user=08dRzK8AAAAJ">
            <img src={gscholarLogo} alt="Google Scholar logo" height="16em" /></a>,&nbsp;
          <a href="https://github.com/ning-y">
            <img src={githubLogo} alt="GitHub logo" height="16em" /></a>,&nbsp;
          <a href="https://www.linkedin.com/in/ning-yuan-lee">
            <img src={linkedinLogo} alt="LinkedIn logo" height="16em" /></a>.
        </p>
        <p className="Contact print-only">
          Email: <a href="mailto:ning@u.duke.nus.edu">ning@u.duke.nus.edu</a>;&nbsp;
          Mobile: +65 9321 0655
        </p>
        <p className="qr print-only">
          <img className="qr" src={qrCode} alt="QR Code" />
        </p>
      </header>
      <div>
        {section2html(EDUCATION)}
        {process.env.NODE_ENV !== "production" ? section2html(EXPERIENCE_OFFLINE) : section2html(EXPERIENCE)}
        {section2html(PUBLICATIONS)}
        {process.env.NODE_ENV !== "production" ? section2html(PUBS_IN_PREP) : <></>}
        {section2html(AWARDS)}
        {section2html(SKILLS)}
      </div>
    </div>
  );
}

function getVariant(): string | null {
  const queryParams = new URLSearchParams(window.location.search);
  const variant = queryParams.get("variant");
  return variant;
}

function section2html(section: Section) {
  return (<div className="Section" key={section.title}>
    <h2> {section.title} </h2>
    <hr />
    {section.entries.map(entry => <Subsection key={entry.title} {...entry}/>)}
  </div>)
}

class Subsection extends Component<Entry> {
  public state: SubsectionState = { isOpen: false };
  private componentRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  private handleClose = () => this.setState({ isOpen: false });
  private handleOpen = () => this.setState({ isOpen: true });

  render() {
    // If "hideUnlessVariant" is a property of this Entry, don't show it by
    // default (the default has getVariant === null) or don't show it if this
    // variant is not one it should be shown (third clause, the includes call)
    if ("hideUnlessVariants" in this.props &&
      (getVariant === null ||
        !this.props.hideUnlessVariants!.includes(getVariant()!))) return (null);

    return (<>
      { this.props.hasOwnProperty("drawer") &&
        <Drawer
          title={<ReactToPrint
            trigger={() => <span className="clickable">{this.props.drawer!.title}</span>}
            content={() => this.componentRef.current}
            documentTitle={`Ning Yuan (A0167286R) - ${this.props.drawer!.title}`}
          /> }
          icon={this.props.drawer!.drawerIcon}
          className="abc" position={Position.RIGHT}
          onClose={this.handleClose} {...this.state} >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY} ref={this.componentRef}>
            <H2 className="print-only">{this.props.drawer!.title}</H2>
            {this.props.drawer!.body}
          </div>
        </div>
      </Drawer> }
      <div>
        <h3>
          {this.props.title}
          {this.props.hasOwnProperty("drawer") &&
            <span className="clickable online-only">
              <Icon icon={this.props.drawer!.clickableIcon} onClick={this.handleOpen} />
            </span>}</h3>
          {this.props.hasOwnProperty("subtitle") &&
            <p className="subtitle"
              dangerouslySetInnerHTML={{__html: this.props.subtitle!}}></p>
          }
        <p className="body" dangerouslySetInnerHTML={{__html: this.props.body}}></p>
      </div>
    </>)
  }
}

class OverlayTip extends Component {
  public state: OverlayState = { isOpen: false };

  private handleClose = () => this.setState({ isOpen: false });

  render() {
    return (
      <Overlay className="online-only" hasBackdrop={false} autoFocus={false} {...this.state}>
        <Card className="overlay-tip" onClick={this.handleClose} interactive={true} elevation={Elevation.FOUR}>
          <p>Welcome to my online CV! I just thought I'd highlight some features which may not be obvious at first: (i) you can click on the <Icon icon="document-open" /> icon where available to read more about an item; (ii) on most desktop browsers, printing this page will allow you to save a print-friendly version as PDF; and (iii) within an opened sidebar (toggled with the <Icon icon="document-open" /> button), you can click on the sidebar title to save a print-friendly PDF of its contents.</p>
        </Card>
      </Overlay>
    )
  }
}

export default App;
